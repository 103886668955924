import React from 'react';
import { COLORS } from '../constants/colors';

function Footer() {
    const styles = {
        footer: {
            backgroundColor: '#f8fafc',
            padding: '40px 0',
            marginTop: '60px',
            borderTop: '1px solid #e2e8f0'
        },
        container: {
            maxWidth: '900px',
            margin: '0 auto',
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '30px'
        },
        description: {
            fontSize: '15px',
            lineHeight: '1.6',
            color: '#475569',
            textAlign: 'center',
            maxWidth: '600px',
            margin: '0 auto',
            '& strong': {
                color: '#0f172a',
                fontWeight: '600'
            }
        },
        social: {
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            margin: '20px 0'
        },
        socialIcon: {
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '50%',
            fontSize: '22px',
            transition: 'all 0.3s ease',
            textDecoration: 'none',
            border: '1px solid #e2e8f0',
            '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                backgroundColor: '#f8fafc',
                borderColor: '#cbd5e1'
            }
        },
        instagramIcon: {
            background: '-webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        },
        facebookIcon: {
            color: '#1877f2'
        },
        googleIcon: {
            color: '#DB4437'
        },
        logo: {
            height: '45px',
            marginTop: '20px'
        },
        copyright: {
            color: '#94a3b8',
            fontSize: '13px',
            textAlign: 'center',
            marginTop: '20px'
        },
        '@media (max-width: 640px)': {
            container: {
                padding: '0 15px'
            },
            description: {
                fontSize: '14px'
            },
            socialIcon: {
                width: '36px',
                height: '36px',
                fontSize: '18px'
            }
        }
    };

    return (
        <footer style={styles.footer}>
            <div style={styles.container}>
                <p style={styles.description}>
                    <strong>Informatique Ste-Foy</strong> est votre partenaire de confiance pour 
                    tous vos besoins en matériel informatique. Notre expertise et notre service 
                    personnalisé vous garantissent les meilleures solutions adaptées à vos besoins.
                </p>

                <div style={styles.social}>
                    <a href="https://www.instagram.com/isf.quebec/" 
                       target="_blank" 
                       rel="noopener noreferrer" 
                       style={styles.socialIcon}
                       aria-label="Instagram">
                        <i className="fab fa-instagram" style={styles.instagramIcon}></i>
                    </a>
                    <a href="https://www.facebook.com/informatiquestefoy" 
                       target="_blank" 
                       rel="noopener noreferrer" 
                       style={styles.socialIcon}
                       aria-label="Facebook">
                        <i className="fab fa-facebook-f" style={styles.facebookIcon}></i>
                    </a>
                    <a href="https://maps.app.goo.gl/yCej73qMDvJuHovc6" 
                       target="_blank" 
                       rel="noopener noreferrer" 
                       style={styles.socialIcon}
                       aria-label="Google My Business">
                        <i className="fab fa-google" style={styles.googleIcon}></i>
                    </a>
                </div>

                <img 
                    src="/informatique-ste-foy.png" 
                    alt="Informatique Ste-Foy" 
                    style={styles.logo}
                />

                <p style={styles.copyright}>
                    © {new Date().getFullYear()} Informatique Ste-Foy. Tous droits réservés.
                </p>
            </div>
        </footer>
    );
}

export default Footer; 