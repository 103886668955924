import React from 'react';
import { COLORS } from '../constants/colors';

function Header() {
    const styles = {
        header: {
            backgroundColor: '#ffffff',
            padding: '10px 0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            transition: 'all 0.3s ease'
        },
        container: {
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 0'
        },
        logo: {
            height: '50px',
            width: 'auto',
            transition: 'all 0.3s ease',
            '@media (max-width: 768px)': {
                height: '40px',
                padding: '5px 0'
            },
            '@media (max-width: 480px)': {
                height: '35px'
            }
        },
        adminLink: {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            color: '#999',
            fontSize: '12px',
            opacity: 0.5,
            textDecoration: 'none',
            zIndex: 1000,
            '&:hover': {
                opacity: 1
            },
            '@media (max-width: 768px)': {
                bottom: '10px',
                right: '10px'
            }
        }
    };

    return (
        <header style={styles.header}>
            <div style={styles.container}>
                <div style={styles.logoContainer}>
                    <img 
                        src="/informatique-ste-foy.png" 
                        alt="Informatique Ste-Foy" 
                        style={styles.logo}
                    />
                </div>
            </div>
            <a href="/admin" style={styles.adminLink}>©</a>
        </header>
    );
}

export default Header; 