import React from 'react';
import { COLORS } from '../constants/colors';

function SearchProgress({ isLoading, currentStep, stepMessage }) {
    const styles = {
        container: {
            width: '100%',
            maxWidth: '900px',
            margin: '20px auto',
            padding: '25px',
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px'
        },
        loadingContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '15px',
            marginBottom: '15px'
        },
        loadingText: {
            color: COLORS.primary.main,
            fontSize: '18px',
            fontWeight: '600',
            letterSpacing: '0.3px'
        },
        stepText: {
            color: COLORS.text.secondary,
            fontSize: '15px',
            textAlign: 'center',
            maxWidth: '600px',
            lineHeight: '1.4'
        },
        progressBarContainer: {
            width: '100%',
            maxWidth: '500px',
            height: '4px',
            backgroundColor: COLORS.background.dark,
            borderRadius: '4px',
            overflow: 'hidden',
            position: 'relative'
        },
        progressBar: {
            height: '100%',
            background: `linear-gradient(90deg, ${COLORS.primary.main}, ${COLORS.primary.light})`,
            position: 'absolute',
            top: 0,
            left: 0,
            animation: 'loading 2s infinite',
            transition: 'width 0.3s ease'
        },
        loadingIcon: {
            fontSize: '24px',
            color: COLORS.primary.main,
            animation: 'pulse 1.5s infinite'
        },
        '@keyframes loading': {
            '0%': {
                left: '-100%'
            },
            '100%': {
                left: '100%'
            }
        },
        '@keyframes pulse': {
            '0%': {
                transform: 'scale(1)'
            },
            '50%': {
                transform: 'scale(1.1)'
            },
            '100%': {
                transform: 'scale(1)'
            }
        }
    };

    if (!isLoading) return null;

    return (
        <div style={styles.container}>
            <div style={styles.loadingContainer}>
                <span role="img" aria-label="search" style={styles.loadingIcon}>🔍</span>
                <div style={styles.loadingText}>
                    Recherche en cours...
                </div>
            </div>
            <div style={styles.stepText}>
                {stepMessage}
            </div>
            <div style={styles.progressBarContainer}>
                <div style={styles.progressBar} />
            </div>
            <style>
                {`
                    @keyframes loading {
                        0% { left: -100%; }
                        100% { left: 100%; }
                    }
                    @keyframes pulse {
                        0% { transform: scale(1); }
                        50% { transform: scale(1.1); }
                        100% { transform: scale(1); }
                    }
                `}
            </style>
        </div>
    );
}

export default SearchProgress; 