export const COLORS = {
    // Couleurs principales
    primary: {
        main: '#1a73e8',      // Bleu principal
        light: '#4285f4',     // Bleu clair
        dark: '#0d47a1',      // Bleu foncé
        background: '#f8fafd'  // Fond bleu très clair
    },
    // Couleurs secondaires
    secondary: {
        green: '#34a853',     // Vert pour succès/garantie
        red: '#ea4335',       // Rouge pour rabais/reset
        yellow: '#fbbc05',    // Jaune pour avertissements
        gray: '#4a5568'       // Gris pour texte secondaire
    },
    // Couleurs de fond
    background: {
        main: '#ffffff',      // Fond principal
        light: '#f8fafd',     // Fond secondaire
        dark: '#e8eef7'       // Fond tertiaire
    },
    // Couleurs de texte
    text: {
        primary: '#2c3e50',   // Texte principal
        secondary: '#4a5568', // Texte secondaire
        light: '#ffffff'      // Texte clair
    },
    // Couleurs de bordure
    border: {
        light: '#e8eef7',     // Bordure claire
        main: '#d1d5db'       // Bordure principale
    },
    // États
    state: {
        success: '#34a853',   // Succès
        error: '#ea4335',     // Erreur
        warning: '#fbbc05',   // Avertissement
        info: '#4285f4'       // Information
    }
}; 