import React from 'react';
import { COLORS } from '../constants/colors';
import html2pdf from 'html2pdf.js';

function ResultsDisplay({ results }) {
    console.log("ResultsDisplay reçoit:", results);

    const styles = {
        container: {
            display: 'grid',
            gridTemplateColumns: '250px repeat(3, 1fr)',
            gap: '15px',
            padding: '30px',
            backgroundColor: COLORS.background.main,
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
            margin: '20px auto',
            maxWidth: '900px'
        },
        header: {
            fontWeight: 'bold',
            padding: '15px',
            backgroundColor: COLORS.primary.main,
            color: 'white',
            textAlign: 'center',
            borderRadius: '8px'
        },
        characteristic: {
            fontWeight: '500',
            padding: '12px 15px',
            backgroundColor: '#f3f6f9',
            borderRadius: '6px',
            color: COLORS.primary.main
        },
        value: {
            padding: '12px',
            backgroundColor: 'white',
            textAlign: 'center',
            borderRadius: '6px',
            border: '1px solid #e8eef7'
        },
        separator: {
            gridColumn: 'span 4',
            borderBottom: '2px solid #e0e0e0',
            margin: '15px 0',
            opacity: 0.7
        },
        boldSeparator: {
            gridColumn: 'span 4',
            borderBottom: '3px solid #d0d0d0',
            margin: '20px 0',
            opacity: 0.8
        },
        guaranteeValue: {
            padding: '8px 12px',
            backgroundColor: '#0073e6',
            color: 'white',
            borderRadius: '20px',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
            fontSize: '14px',
            fontWeight: '600',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        },
        discountValue: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '8px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            border: '2px solid #e9ecef'
        },
        discountAmount: {
            color: '#dc3545',
            fontSize: '18px',
            fontWeight: '700'
        },
        discountLabel: {
            fontSize: '12px',
            color: '#6c757d',
            fontStyle: 'italic',
            marginTop: '2px'
        },
        priceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            padding: '12px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        },
        originalPrice: {
            textDecoration: 'line-through',
            color: '#6c757d',
            fontSize: '14px'
        },
        finalPrice: {
            color: '#00274D',
            fontSize: '24px',
            fontWeight: '700'
        },
        bestOffer: {
            backgroundColor: '#ffd700',
            color: '#000'
        },
        goodValue: {
            backgroundColor: '#e3f2fd',
            color: '#0073e6'
        },
        qualityPrice: {
            backgroundColor: '#e8f5e9',
            color: '#2c3e50'
        },
        offerHeader: {
            padding: '20px 15px',
            backgroundColor: 'white',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '140px',
            border: '1px solid #e8eef7',
            position: 'relative'
        },
        badge: {
            position: 'absolute',
            top: '-12px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '8px 20px',
            borderRadius: '25px',
            fontSize: '13px',
            fontWeight: '600',
            letterSpacing: '0.3px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            whiteSpace: 'nowrap',
            border: '1px solid transparent'
        },
        bestOfferBadge: {
            backgroundColor: '#fff',
            color: '#f5a623',
            borderColor: '#f5a623',
            fontWeight: '600',
            boxShadow: '0 2px 8px rgba(245, 166, 35, 0.15)',
            padding: '8px 20px',
            '&::before': {
                content: '"★"',
                marginRight: '5px',
                color: '#f5a623'
            }
        },
        bestOfferPrice: {
            color: '#f5a623',
            fontSize: '28px',
            fontWeight: '700',
            background: 'linear-gradient(45deg, #f5a623, #f8c471)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 1px 2px rgba(0,0,0,0.05)'
        },
        bestOfferEconomy: {
            color: '#e74c3c',
            fontSize: '15px',
            fontWeight: '500',
            padding: '4px 12px',
            backgroundColor: 'rgba(231, 76, 60, 0.08)',
            borderRadius: '15px',
            display: 'inline-block',
            margin: '6px 0'
        },
        goodValueBadge: {
            backgroundColor: '#E3F2FD',
            color: '#0D47A1',
            borderColor: '#64B5F6'
        },
        qualityPriceBadge: {
            backgroundColor: '#E8F5E9',
            color: '#1B5E20',
            borderColor: '#81C784'
        },
        priceInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: '15px'
        },
        priceRow: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginTop: '10px'
        },
        originalPrice: {
            color: '#6c757d',
            textDecoration: 'line-through',
            fontSize: '14px'
        },
        discountInfo: {
            fontSize: '14px',
            color: '#dc3545',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
        },
        finalPrice: {
            fontSize: '28px',
            fontWeight: '700',
            color: '#0056b3',
            marginTop: '10px'
        },
        divider: {
            width: '40px',
            height: '2px',
            backgroundColor: '#e9ecef',
            margin: '8px 0'
        },
        mainTitle: {
            color: '#0056b3',
            fontSize: '24px',
            fontWeight: '600',
            textAlign: 'center',
            gridColumn: 'span 4',
            marginBottom: '25px',
            borderBottom: '2px solid #e9ecef',
            paddingBottom: '15px'
        },
        pdfSection: {
            textAlign: 'center',
            marginTop: '30px',
            marginBottom: '40px'
        },
        pdfButton: {
            backgroundColor: '#0056b3',
            color: 'white',
            padding: '12px 24px',
            borderRadius: '6px',
            border: 'none',
            fontSize: '16px',
            fontWeight: '500',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            margin: '0 auto',
            transition: 'background-color 0.3s ease',
            '&:hover': {
                backgroundColor: '#003d82'
            }
        },
        offerColumn: {
            position: 'relative',
            padding: '25px 15px 15px',
            backgroundColor: 'white',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #e8eef7',
            minHeight: '120px',
            transition: 'all 0.3s ease'
        },
        bestOfferColumn: {
            backgroundColor: '#fdfaf6',
            boxShadow: '0 4px 15px rgba(245, 166, 35, 0.08)',
            border: '1px solid #f5d6a5',
            transform: 'translateY(-4px)',
            position: 'relative',
            transition: 'all 0.3s ease',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: -1,
                bottom: -1,
                left: -1,
                width: '3px',
                background: 'linear-gradient(to bottom, #f5a623, #f8c471)',
                borderRadius: '3px 0 0 3px'
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                top: -1,
                bottom: -1,
                right: -1,
                width: '3px',
                background: 'linear-gradient(to bottom, #f5a623, #f8c471)',
                borderRadius: '0 3px 3px 0'
            }
        },
        regularColumn: {
            '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
            }
        },
        badge: {
            position: 'absolute',
            top: '-12px',
            padding: '6px 16px',
            borderRadius: '20px',
            fontSize: '13px',
            fontWeight: '600',
            letterSpacing: '0.3px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            whiteSpace: 'nowrap',
            border: '1px solid transparent',
            left: '50%',
            transform: 'translateX(-50%)'
        },
        priceInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px',
            marginTop: '8px'
        },
        oldPrice: {
            textDecoration: 'line-through',
            color: '#6c757d',
            fontSize: '14px'
        },
        economy: {
            color: '#dc3545',
            fontSize: '14px',
            fontWeight: '500'
        },
        finalPrice: {
            color: '#0056b3',
            fontSize: '24px',
            fontWeight: '700',
            marginTop: '4px'
        },
        guaranteeContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '4px'
        },
        guaranteeValue: {
            padding: '6px 12px',
            borderRadius: '20px',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
            fontSize: '14px',
            fontWeight: '600',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            border: '1px solid transparent'
        },
        guarantee1Year: {
            backgroundColor: '#E3F2FD',
            color: '#1976D2',
            borderColor: '#90CAF9'
        },
        guarantee2Years: {
            backgroundColor: '#E8F5E9',
            color: '#2E7D32',
            borderColor: '#A5D6A7'
        },
        guarantee3Years: {
            backgroundColor: '#FFF3E0',
            color: '#E65100',
            borderColor: '#FFCC80'
        },
        guaranteeLabel: {
            fontSize: '12px',
            color: '#666',
            marginTop: '2px'
        }
    };

    // Fonction pour générer un ID unique
    const generateDocumentId = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
        
        return `DOC-${year}${month}${day}-${hours}${minutes}${seconds}-${random}`;
    };

    const generatePDF = () => {
        const documentId = generateDocumentId();
        const currentDate = new Date().toLocaleDateString('fr-CA');
        
        // Fonction pour détecter si on est sur mobile
        const isMobile = window.innerWidth < 768;

        const pdfContent = document.createElement('div');
        pdfContent.innerHTML = `
            <div style="padding: 20px; font-family: Arial, sans-serif;">
                <!-- En-tête amélioré -->
                <div style="display: flex; justify-content: space-between; margin-bottom: 30px;">
                    <!-- Logo et infos légales -->
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 15px; flex: 1;">
                        <img src="/informatique-ste-foy.png" alt="Logo" style="height: 66px; margin-bottom: 15px;">
                        <div style="text-align: left; color: #666; font-size: 11px;">
                            <p style="margin: 0;">TPS: 789742285 RT0001</p>
                            <p style="margin: 3px 0;">TVQ: 1225128231 TQ0001</p>
                        </div>
                    </div>
                    
                    <!-- Coordonnées et ID -->
                    <div style="text-align: right;">
                        <p style="color: #666; font-size: 14px; margin: 0 0 5px 0;">
                            1-979 av de Bourgogne<br>
                            Québec, QC G1W 2L4<br>
                            Tél : (418) 255-8998
                        </p>
                        <div style="display: flex; align-items: center; justify-content: flex-end; gap: 5px; margin-top: 10px;">
                            <span style="color: #0056b3;">🔒</span>
                            <span style="font-family: monospace; color: #666; font-size: 12px;">Réf: ${documentId}</span>
                        </div>
                    </div>
                </div>

                <!-- Titre principal -->
                <div style="text-align: center; margin-bottom: 25px; padding: 15px; background-color: #f8f9fa; border-radius: 8px;">
                    <h2 style="color: #0056b3; font-size: 24px; margin: 0;">Comparatif des Offres</h2>
                    <p style="color: #666; font-size: 14px; margin: 5px 0 0 0;">
                        Document généré le ${currentDate}
                    </p>
                </div>

                <!-- Tableau principal avec style amélioré -->
                <table style="width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 12px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                    <!-- En-têtes des colonnes -->
                    <tr style="background-color: #f8f9fa;">
                        <th style="padding: 12px; border: 1px solid #dee2e6; width: 25%;">Caractéristiques</th>
                        ${[0, 1, 2].map(index => `
                            <th style="padding: 12px; border: 1px solid #dee2e6; background-color: ${
                                index === 1 ? '#fff3e0' : '#ffffff'
                            };">
                                ${index === 1 ? "Meilleure offre" : index === 0 ? "Bon rapport" : "Qualité/Prix"}
                            </th>
                        `).join('')}
                    </tr>

                    <!-- Prix -->
                    <tr>
                        <td style="padding: 12px; border: 1px solid #dee2e6; background-color: #f8f9fa;">Prix</td>
                        ${[0, 1, 2].map(index => {
                            const prixBase = parseFloat(results[index]?.['PRIX FINAL'] || 0);
                            const margeProfit = prixBase * 0.25;
                            const fraisLivraison = 30;
                            const prixAvantRabais = prixBase + margeProfit + fraisLivraison;
                            const rabaisTotal = ['RABAIS ADDITIONNEL', 'PROMOTION', 'RABAIS RECONDITIONNÉ', 'RABAIS IMP']
                                .reduce((total, key) => total + parseFloat(results[index]?.[key] || 0), 0);
                            const prixFinal = prixAvantRabais - rabaisTotal;

                            return `
                                <td style="padding: 12px; border: 1px solid #dee2e6; text-align: center;">
                                    <div style="text-decoration: line-through; color: #6c757d;">${prixAvantRabais.toFixed(2)} $</div>
                                    ${rabaisTotal > 0 ? `<div style="color: #dc3545;">-${rabaisTotal.toFixed(2)} $</div>` : ''}
                                    <div style="color: #0056b3; font-weight: bold; font-size: 16px;">${prixFinal.toFixed(2)} $</div>
                                    <div style="color: #6c757d; font-size: 12px; margin-top: 4px;">Prix avant taxes</div>
                                </td>
                            `;
                        }).join('')}
                    </tr>

                    <!-- Caractéristiques techniques -->
                    ${[
                        { key: 'MODELE', label: 'Modèle' },
                        { key: 'PROCESSEUR', label: 'Processeur' },
                        { key: 'MÉMOIRE', label: 'Mémoire' },
                        { key: 'HDD', label: 'Stockage' },
                        { key: 'VIDEOCARD', label: 'Carte Graphique' },
                        { key: 'WINDOWS', label: 'Windows' },
                        { key: 'CONDITION', label: 'Condition' }
                    ].map(({ key, label }) => `
                        <tr>
                            <td style="padding: 12px; border: 1px solid #dee2e6; background-color: #f8f9fa;">${label}</td>
                            ${[0, 1, 2].map(index => `
                                <td style="padding: 12px; border: 1px solid #dee2e6; text-align: center;">
                                    ${results[index]?.[key] || '-'}
                                </td>
                            `).join('')}
                        </tr>
                    `).join('')}

                    <!-- Garantie -->
                    <tr>
                        <td style="padding: 12px; border: 1px solid #dee2e6; background-color: #f8f9fa;">Garantie</td>
                        ${[0, 1, 2].map(index => `
                            <td style="padding: 12px; border: 1px solid #dee2e6; text-align: center;">
                                <div style="color: #0056b3; font-weight: bold;">${results[index]?.GARANTIE || '-'}</div>
                            </td>
                        `).join('')}
                    </tr>
                </table>

                <!-- Note et pied de page -->
                <div style="margin-top: 40px;">
                    <!-- Section Note -->
                    <div style="text-align: left; margin-bottom: 20px;">
                        <p style="color: #666; font-size: 12px; margin: 0; line-height: 1.5;">
                            <strong style="color: #0056b3;">Note :</strong><br>
                            - Cette offre est valable sous réserve de disponibilité.<br>
                            - La disponibilité en ligne n'est pas garantie à 100%.<br>
                            - Les prix indiqués sont avant taxes.
                        </p>
                    </div>

                    <!-- Message de Contact -->
                    <div style="text-align: left; margin: 20px 0; padding: 15px; background-color: #f8f9fa; border-radius: 8px;">
                        <p style="color: #333; font-size: 12px; margin: 0; line-height: 1.5;">
                            Pour confirmer cette offre et recevoir votre facture, veuillez nous contacter à l'adresse suivante : 
                            <a href="mailto:contact@informatique-ste-foy.com" style="color: #0056b3; text-decoration: none; font-weight: 500;">
                                contact@informatique-ste-foy.com
                            </a>
                        </p>
                    </div>

                    <!-- Section Contact et Légal -->
                    <div style="
                        display: flex; 
                        flex-direction: ${isMobile ? 'column' : 'row'}; 
                        gap: ${isMobile ? '20px' : '0'}; 
                        justify-content: ${isMobile ? 'center' : 'space-between'};
                        margin-top: 20px; 
                        padding-top: 20px; 
                        border-top: 1px solid #eee;
                    ">
                        <div style="
                            color: #666; 
                            font-size: 12px;
                            text-align: ${isMobile ? 'center' : 'left'};
                        ">
                            <p style="margin: 0;">www.informatique-ste-foy.com</p>
                            <p style="margin: 5px 0 0 0;">Support client : (418) 255-8998</p>
                        </div>
                        <div style="text-align: ${isMobile ? 'center' : 'right'};">
                            <p style="margin: 0; color: #666; font-size: 12px;">
                                Document généré le ${currentDate}
                            </p>
                            <p style="margin: 5px 0 0 0; font-size: 11px; color: #666;">
                                © ${new Date().getFullYear()} Informatique Ste-Foy. Tous droits réservés.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        `;

        const opt = {
            margin: 3,  // Réduction des marges à 3mm
            filename: `Comparatif_${documentId}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { 
                scale: 2,
                useCORS: true,
                logging: false
            },
            jsPDF: { 
                unit: 'mm', 
                format: 'a4', 
                orientation: 'portrait',
                compress: true,
                putOnlyUsedFonts: true,
                floatPrecision: 16
            },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        html2pdf().from(pdfContent).set(opt).save();
    };

    if (!results || results.length === 0) {
        return null;
    }

    // Fonction pour déterminer le style de la garantie
    const getGuaranteeStyle = (guarantee) => {
        if (!guarantee) return null;
        const years = parseInt(guarantee);
        switch(years) {
            case 1:
                return styles.guarantee1Year;
            case 2:
                return styles.guarantee2Years;
            case 3:
                return styles.guarantee3Years;
            default:
                return null;
        }
    };

    // Fonction pour obtenir l'icône de la garantie
    const getGuaranteeIcon = (years) => {
        switch(years) {
            case 1:
                return '🛡️';
            case 2:
                return '🛡️🛡️';
            case 3:
                return '🛡️🛡️🛡️';
            default:
                return '🛡️';
        }
    };

    return (
        <>
            <div id="results-for-pdf" style={styles.container}>
                <div style={styles.mainTitle}>Comparatif des Offres</div>
                
                {/* En-têtes avec prix */}
                <div style={{...styles.characteristic, visibility: 'hidden'}}>Espace</div>
                {[0, 1, 2].map(index => {
                    const columnStyle = {
                        ...styles.offerColumn,
                        ...(index === 1 ? styles.bestOfferColumn : styles.regularColumn)
                    };

                    const priceStyle = index === 1 ? styles.bestOfferPrice : styles.finalPrice;
                    const economyStyle = index === 1 ? styles.bestOfferEconomy : styles.economy;
                    const badgeStyle = {
                        ...styles.badge,
                        ...(index === 1 ? styles.bestOfferBadge : 
                            index === 0 ? styles.goodValueBadge : styles.qualityPriceBadge)
                    };

                    const prixBase = parseFloat(results[index]?.['PRIX FINAL'] || 0);
                    const margeProfit = prixBase * 0.25;
                    const fraisLivraison = 30;
                    const prixAvantRabais = prixBase + margeProfit + fraisLivraison;
                    const rabaisTotal = ['RABAIS ADDITIONNEL', 'PROMOTION', 'RABAIS RECONDITIONNÉ', 'RABAIS IMP']
                        .reduce((total, key) => total + parseFloat(results[index]?.[key] || 0), 0);
                    const prixFinal = prixAvantRabais - rabaisTotal;

                    return (
                        <div key={`header-${index}`} style={columnStyle}>
                            <div style={badgeStyle}>
                                {index === 1 ? "Meilleure offre" : index === 0 ? "Bon rapport" : "Qualité/Prix"}
                            </div>
                            <div style={styles.priceInfo}>
                                <span style={styles.oldPrice}>{prixAvantRabais.toFixed(2)} $</span>
                                <span style={{
                                    ...styles.economy,
                                    color: rabaisTotal > 0 ? '#dc3545' : '#6c757d'
                                }}>
                                    {rabaisTotal > 0 
                                        ? `Économie : -${rabaisTotal.toFixed(2)} $`
                                        : "Aucune réduction"}
                                </span>
                                <span style={styles.finalPrice}>{prixFinal.toFixed(2)} $</span>
                            </div>
                        </div>
                    );
                })}

                {/* Séparateur après les en-têtes */}
                <div style={styles.boldSeparator} />

                {/* Section Spécifications Techniques */}
                <div style={styles.characteristic}>Type</div>
                {[0, 1, 2].map(index => (
                    <div key={`type-${index}`} style={styles.value}>
                        {results[index]?.ASSET || '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Modèle</div>
                {[0, 1, 2].map(index => (
                    <div key={`model-${index}`} style={styles.value}>
                        {results[index]?.MODELE || '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Processeur</div>
                {[0, 1, 2].map(index => (
                    <div key={`cpu-${index}`} style={styles.value}>
                        {results[index]?.PROCESSEUR || '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Mémoire</div>
                {[0, 1, 2].map(index => (
                    <div key={`ram-${index}`} style={styles.value}>
                        {results[index]?.['MÉMOIRE'] ? `${results[index]['MÉMOIRE']} GB` : '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Stockage</div>
                {[0, 1, 2].map(index => (
                    <div key={`storage-${index}`} style={styles.value}>
                        {results[index]?.HDD || '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Carte Graphique</div>
                {[0, 1, 2].map(index => (
                    <div key={`gpu-${index}`} style={styles.value}>
                        {results[index]?.VIDEOCARD || '-'}
                    </div>
                ))}

                <div style={styles.characteristic}>Windows</div>
                {[0, 1, 2].map(index => (
                    <div key={`windows-${index}`} style={styles.value}>
                        {results[index]?.WINDOWS || '-'}
                    </div>
                ))}

                {/* Type d'Écran - Affichage conditionnel */}
                {results.some(result => result?.['DISPLAY TYPE'] && result?.ASSET !== 'Desktop') && (
                    <>
                        <div style={styles.characteristic}>Type d'Écran</div>
                        {[0, 1, 2].map(index => (
                            <div key={`display-${index}`} style={styles.value}>
                                {results[index]?.['DISPLAY TYPE'] || '-'}
                            </div>
                        ))}
                    </>
                )}

                {/* Commenté temporairement - Numéro de Série */}
                {/*
                <div style={styles.characteristic}>Numéro de Série</div>
                {[0, 1, 2].map(index => (
                    <div key={`serial-${index}`} style={styles.value}>
                        {results[index]?.['NUMERO DE SERIE'] || '-'}
                    </div>
                ))}
                */}

                {/* Séparateur 1 */}
                <div style={styles.boldSeparator} />

                {/* Section 2: État et Garantie */}
                <div style={styles.characteristic}>Condition</div>
                {[0, 1, 2].map(index => (
                    <div key={`condition-${index}`} style={styles.value}>
                        {results[index]?.CONDITION || '-'}
                    </div>
                ))}

                {/* État du Produit - Affichage conditionnel */}
                {results.some(result => result?.['ETAT PRODUIT']) && (
                    <>
                        <div style={styles.characteristic}>État du Produit</div>
                        {[0, 1, 2].map(index => (
                            <div key={`state-${index}`} style={styles.value}>
                                {results[index]?.['ETAT PRODUIT'] || '-'}
                            </div>
                        ))}
                    </>
                )}

                <div style={styles.characteristic}>
                    <span role="img" aria-label="shield">🛡️</span> Garantie
                </div>
                {[0, 1, 2].map(index => {
                    const guarantee = results[index]?.GARANTIE;
                    const years = guarantee ? parseInt(guarantee) : 0;
                    const guaranteeStyle = getGuaranteeStyle(guarantee);

                    return (
                        <div key={`warranty-${index}`} style={styles.value}>
                            {guarantee ? (
                                <div style={styles.guaranteeContainer}>
                                    <div style={{
                                        ...styles.guaranteeValue,
                                        ...(guaranteeStyle || {})
                                    }}>
                                        <span>{getGuaranteeIcon(years)}</span>
                                        {guarantee}
                                    </div>
                                    <span style={styles.guaranteeLabel}>
                                        {years > 1 ? 'Garantie étendue' : 'Garantie standard'}
                                    </span>
                                </div>
                            ) : '-'}
                        </div>
                    );
                })}

                {/* Séparateur 2 */}
                <div style={styles.boldSeparator} />

                {/* Commenté temporairement - Score */}
                {/*
                <div style={styles.characteristic}>Score</div>
                {[0, 1, 2].map(index => (
                    <div key={`score-${index}`} style={styles.value}>
                        {results[index]?.score ? `${results[index].score}/100` : '-'}
                    </div>
                ))}
                */}
            </div>
            
            <div style={styles.pdfSection}>
                <button 
                    onClick={generatePDF} 
                    style={styles.pdfButton}
                >
                    <span role="img" aria-label="print">🖨️</span>
                    Télécharger en PDF
                </button>
            </div>
        </>
    );
}

export default ResultsDisplay;