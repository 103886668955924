import React, { useState } from 'react';
import Header from './components/Header';
import SearchForm from './components/SearchForm';
import ResultsDisplay from './components/ResultsDisplay';
import SearchProgress from './components/SearchProgress';
import Footer from './components/Footer';
import { COLORS } from './constants/colors';

function App() {
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchStep, setSearchStep] = useState(0);

    const searchSteps = [
        "Initialisation de la recherche...",
        "Analyse de vos critères de sélection...",
        "Scan de notre base de données...",
        "Filtrage des configurations disponibles...",
        "Comparaison des spécifications techniques...",
        "Évaluation des rapports qualité/prix...",
        "Vérification des promotions en cours...",
        "Calcul des scores de recommandation...",
        "Sélection des meilleures offres...",
        "Préparation des résultats..."
    ];

    const handleSearch = async (searchParams) => {
        setError(null);
        setIsLoading(true);
        setSearchStep(0);

        try {
            // Simulation des étapes
            for (let i = 0; i < searchSteps.length; i++) {
                setSearchStep(i);
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            console.log("Envoi des paramètres:", searchParams);  // Debug
            const response = await fetch(`http://localhost:3001/api/recommendations?${new URLSearchParams(searchParams)}`);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            console.log("Données reçues:", data);  // Debug
            
            if (Array.isArray(data) && data.length > 0) {
                setResults(data);
            } else {
                throw new Error('Aucun résultat trouvé');
            }
        } catch (error) {
            console.error('Erreur:', error);
            setError("Une erreur s'est produite lors de la recherche. Veuillez réessayer.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <main style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px', minHeight: 'calc(100vh - 200px)' }}>
                <SearchForm onSearch={handleSearch} />
                {error && (
                    <div style={{
                        color: '#721c24',
                        backgroundColor: '#f8d7da',
                        padding: '10px',
                        margin: '10px auto',
                        maxWidth: '1000px',
                        borderRadius: '4px',
                        textAlign: 'center'
                    }}>
                        {error}
                    </div>
                )}
                <SearchProgress 
                    isLoading={isLoading} 
                    currentStep={searchStep}
                    stepMessage={searchSteps[searchStep]}
                />
                {!isLoading && results && results.length > 0 && (
                    <ResultsDisplay results={results} />
                )}
            </main>
            <Footer />
        </div>
    );
}

export default App;
