import React, { useState, useEffect } from 'react';
import { COLORS } from '../constants/colors';

function SearchForm({ onSearch }) {
    const [type, setType] = useState('');
    const [budget, setBudget] = useState('');
    const [ram, setRam] = useState('');
    const [hdd, setHdd] = useState('');
    const [processor, setProcessor] = useState('');
    const [usage, setUsage] = useState('');
    const [keyboard, setKeyboard] = useState('');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [typedText, setTypedText] = useState('');
    const fullText = 'Notre algorithme analyse plus de 1000 configurations pour vous recommander les meilleures options selon vos besoins.';

    useEffect(() => {
        // Animation d'apparition au scroll
        const handleScroll = () => {
            const element = document.getElementById('searchFormContainer');
            if (element) {
                const position = element.getBoundingClientRect();
                if (position.top < window.innerHeight - 100) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Vérification initiale

        // Effet machine à écrire
        if (isVisible && !isTyping) {
            setIsTyping(true);
            let currentText = '';
            let currentIndex = 0;

            const typeInterval = setInterval(() => {
                if (currentIndex < fullText.length) {
                    currentText += fullText[currentIndex];
                    setTypedText(currentText);
                    currentIndex++;
                } else {
                    clearInterval(typeInterval);
                }
            }, 30);
        }

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isVisible, isTyping]);

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch({ type, budget, ram, hdd, processor, usage, keyboard });
    };

    const handleReset = () => {
        setType('');
        setBudget('');
        setRam('');
        setHdd('');
        setProcessor('');
        setUsage('');
        setKeyboard('');
    };

    const styles = {
        formContainer: {
            padding: '25px',
            margin: '20px auto',
            maxWidth: '900px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
            border: '1px solid #edf2f7',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
        },
        title: {
            fontSize: '2em',
            fontWeight: '700',
            color: '#1D3557',
            textAlign: 'center',
            marginBottom: '25px',
            letterSpacing: '0.5px',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
            transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
            transitionDelay: '0.2s',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            '&::after': {
                content: '""',
                display: 'block',
                width: '60px',
                height: '3px',
                backgroundColor: '#1D3557',
                margin: '15px auto 0',
                borderRadius: '2px',
                opacity: 0.7
            }
        },
        titleIcon: {
            fontSize: '24px',
            marginRight: '8px'
        },
        introTextContainer: {
            textAlign: 'center',
            maxWidth: '700px',
            margin: '0 auto 30px',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'all 0.6s ease-out',
            transitionDelay: '0.4s'
        },
        introText: {
            fontSize: '1.1em',
            color: '#333',
            lineHeight: '1.5',
            fontWeight: '400',
            margin: '0',
            padding: '0 20px'
        },
        highlight: {
            color: '#1D3557',
            fontWeight: '500'
        },
        lineBreak: {
            display: 'block',
            margin: '8px 0'
        },
        fieldContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '20px',
            padding: '20px',
            backgroundColor: '#f8fafc',
            borderRadius: '12px',
            marginBottom: '20px',
            '@media (max-width: 768px)': {
                gridTemplateColumns: '1fr'
            },
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
            transitionDelay: '0.6s'
        },
        inputGroup: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(-20px)',
            transition: 'opacity 0.4s ease-out, transform 0.4s ease-out'
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            fontSize: '14px',
            color: '#334155',
            fontWeight: '500',
            letterSpacing: '0.3px'
        },
        icon: {
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            backgroundColor: '#e2e8f0',
            color: COLORS.primary.main,
            fontSize: '16px',
            transition: 'all 0.2s ease'
        },
        select: {
            width: '100%',
            padding: '12px 15px',
            borderRadius: '10px',
            border: '1.5px solid #e2e8f0',
            fontSize: '14px',
            backgroundColor: 'white',
            color: '#334155',
            transition: 'all 0.2s ease',
            cursor: 'pointer',
            '&:hover': {
                borderColor: COLORS.primary.light,
                backgroundColor: '#f8fafc'
            },
            '&:focus': {
                outline: 'none',
                borderColor: COLORS.primary.main,
                boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.1)'
            }
        },
        advancedToggle: {
            width: '100%',
            padding: '12px',
            backgroundColor: '#f1f5f9',
            border: 'none',
            borderRadius: '10px',
            color: COLORS.primary.main,
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: '#e2e8f0'
            },
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'all 0.4s ease-out',
            transitionDelay: '0.8s'
        },
        advancedSection: {
            maxHeight: showAdvanced ? '1000px' : '0',
            opacity: showAdvanced ? 1 : 0,
            overflow: 'hidden',
            transition: 'all 0.3s ease-in-out',
            marginTop: showAdvanced ? '20px' : '0'
        },
        buttonGroup: {
            display: 'flex',
            gap: '15px',
            justifyContent: 'center',
            marginTop: '30px',
            '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '10px'
            },
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            transition: 'all 0.4s ease-out',
            transitionDelay: '1s'
        },
        baseButton: {
            padding: '14px 30px',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '12px',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            transition: 'all 0.3s ease',
            minWidth: '200px',
            letterSpacing: '0.5px',
            '@media (max-width: 768px)': {
                width: '100%',
                padding: '12px 20px'
            }
        },
        searchButton: {
            backgroundColor: COLORS.primary.main,
            color: 'white',
            boxShadow: '0 4px 6px rgba(59, 130, 246, 0.25)',
            '&:hover': {
                backgroundColor: '#1d4ed8',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 12px rgba(59, 130, 246, 0.3)'
            }
        },
        resetButton: {
            backgroundColor: '#f1f5f9',
            color: '#64748b',
            '&:hover': {
                backgroundColor: '#e2e8f0',
                transform: 'translateY(-2px)'
            }
        },
        buttonIcon: {
            fontSize: '20px'
        },
        advancedFields: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '20px',
            padding: '20px',
            backgroundColor: '#f8fafc',
            borderRadius: '12px',
            marginTop: '20px'
        }
    };

    const fieldIcons = {
        'Budget': '💰',
        "Type d'appareil": '💻',
        'Utilisation Prévue': '🎯',
        'Mémoire RAM': '🧠',
        'Processeur': '⚡',
        'Disque Dur': '💾'
    };

    const tooltips = {
        "type d'appareil": "Choisissez le type d'ordinateur qui correspond à vos besoins (Desktop pour bureau fixe, Laptop pour portable...)",
        "budget ($)": "Indiquez votre budget maximum en dollars canadiens. Notre système trouvera les meilleures options dans cette gamme de prix",
        "mémoire ram": "La RAM détermine la capacité de votre ordinateur à gérer plusieurs tâches simultanément. Plus de RAM = plus de performance",
        "disque dur": "Choisissez la capacité de stockage. Plus la capacité est grande, plus vous pourrez stocker de fichiers",
        "processeur": "Le processeur est le cerveau de l'ordinateur. Plus il est puissant, plus votre ordinateur sera rapide",
        "utilisation prévue": "Sélectionnez votre utilisation principale pour obtenir des recommandations adaptées à vos besoins spécifiques"
    };

    const formFields = [
        {
            label: "Type d'appareil",
            value: type,
            onChange: setType,
            type: 'select',
            className: 'primary-field',
            options: [
                { value: '', label: 'Sélectionnez un type' },
                { value: 'Desktop', label: 'Ordinateur de bureau (Desktop)' },
                { value: 'Laptop', label: 'Ordinateur portable (Laptop)' },
                { value: 'AIO', label: 'Tout-en-un (All-in-One)' }
            ]
        },
        {
            label: 'Budget',
            value: budget,
            onChange: setBudget,
            type: 'select',
            className: 'primary-field',
            options: [
                { value: '', label: 'Sélectionnez votre budget' },
                { value: '500', label: '400$ - 600$' },
                { value: '700', label: '600$ - 800$' },
                { value: '900', label: '800$ - 1000$' },
                { value: '1200', label: '1000$ - 1400$' },
                { value: '1500', label: '1400$ - 1600$' },
                { value: '2000', label: '1600$ - 2400$' },
                { value: '2500', label: '2400$ et plus' }
            ]
        },
        {
            label: 'Utilisation Prévue',
            value: usage,
            onChange: setUsage,
            type: 'select',
            className: 'primary-field',
            options: [
                { value: '', label: "À quoi servira votre ordinateur?" },
                { value: 'BASIC', label: 'Usage basique (Internet, Email, Office)' },
                { value: 'WORK', label: 'Travail et Télétravail' },
                { value: 'GAMING', label: 'Jeux vidéo (Gaming)' },
                { value: 'TRADING', label: 'Trading et Finance' },
                { value: 'DESIGN', label: 'Design et Création' },
                { value: 'DEV', label: 'Développement et Programmation' }
            ]
        },
        {
            label: 'Disque Dur',
            value: hdd,
            onChange: setHdd,
            type: 'select',
            options: [
                { value: '', label: 'Sélectionnez le stockage' },
                { value: '256 GB', label: '256 GB - Stockage minimal' },
                { value: '500 GB', label: '500 GB - Stockage moyen' },
                { value: '1.0 TB', label: '1 TB - Grand stockage' },
                { value: '2.0 TB', label: '2 TB - Très grand stockage' }
            ]
        }
    ];

    const primaryFields = formFields;
    const secondaryFields = [
        {
            label: 'Mémoire RAM',
            value: ram,
            onChange: setRam,
            type: 'select',
            options: [
                { value: 'any', label: 'Je ne sais pas / Peu importe' },
                { value: '8', label: '8 GB - Usage basique' },
                { value: '16', label: '16 GB - Usage avancé' },
                { value: '32', label: '32 GB - Usage professionnel' },
                { value: '64', label: '64 GB - Usage intensif' }
            ]
        },
        {
            label: 'Processeur',
            value: processor,
            onChange: setProcessor,
            type: 'select',
            options: [
                { value: '', label: 'Je ne sais pas / Peu importe' },
                { value: 'i3', label: 'Intel Core i3 - Performance basique' },
                { value: 'i5', label: 'Intel Core i5 - Bonne performance' },
                { value: 'i7', label: 'Intel Core i7 - Haute performance' },
                { value: 'i9', label: 'Intel Core i9 - Performance maximale' }
            ]
        }
    ];

    return (
        <div id="searchFormContainer" style={styles.formContainer}>
            <h1 style={styles.title}>
                <span style={styles.titleIcon}>🛡️</span>
                Trouvez l'Ordinateur Qui Vous Correspond !
            </h1>
            
            <div style={styles.introTextContainer}>
                <p style={styles.introText}>
                    Notre <span style={styles.highlight}>algorithme intelligent</span> analyse 
                    plus de <span style={styles.highlight}>1000 configurations</span>
                    <span style={styles.lineBreak} />
                    pour vous recommander les <span style={styles.highlight}>meilleures options</span> 
                    selon vos besoins.
                </p>
            </div>

            <form onSubmit={handleSubmit}>
                <div style={styles.fieldContainer}>
                    {primaryFields.map((field, index) => (
                        <div key={index} style={styles.inputGroup}>
                            <label style={styles.label}>
                                <span style={styles.icon}>{fieldIcons[field.label]}</span>
                                {field.label}
                            </label>
                            <select
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                                style={styles.select}
                                required={field.required !== false}
                            >
                                {field.options.map((option, idx) => (
                                    <option key={idx} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>

                <button 
                    type="button"
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    style={styles.advancedToggle}
                >
                    Options avancées {showAdvanced ? '▼' : '▲'}
                </button>

                <div style={styles.advancedSection}>
                    <div style={styles.advancedFields}>
                        {secondaryFields.map((field, index) => (
                            <div key={index} style={styles.inputGroup}>
                                <label style={styles.label}>
                                    <span style={styles.icon}>{fieldIcons[field.label]}</span>
                                    {field.label}
                                </label>
                                <select
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.target.value)}
                                    style={styles.select}
                                    required={false}
                                >
                                    {field.options.map((option, idx) => (
                                        <option key={idx} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ))}
                    </div>
                </div>

                <div style={styles.buttonGroup}>
                    <button 
                        type="submit" 
                        style={{...styles.baseButton, ...styles.searchButton}}
                    >
                        <span style={styles.buttonIcon}>🔍</span>
                        Rechercher
                    </button>
                    <button 
                        type="button" 
                        onClick={handleReset}
                        style={{...styles.baseButton, ...styles.resetButton}}
                    >
                        <span style={styles.buttonIcon}>↺</span>
                        Réinitialiser
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SearchForm;